export default {

    // namespaced: true,
    state: {
        user: {
            login: 'dev@technoplus.com.br',
            pass: 'dEvel@Tech$xy65%4'
        }
    },

    mutations: {},

}