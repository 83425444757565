<template>
    <div id="novaSenha">

        <div class="background">

            <div style="width: 300px" v-if="this.validado">

                <b-card class="card">

                    <b-img center src="../../../assets/logo/technoPlus.png" alt="technoPlus"></b-img>

                    <br>

                    <h4>Redefinir Senha</h4>

                    <br>

                    <!-- Apenas para impedir o preenchimento automático -->
                    <b-form-input type="password" class="input form-group" hidden></b-form-input> 
                    <!-- ------------------------------------------------------- -->

                    <b-form-input type="password" class="input form-group" placeholder="Digite sua nova senha " v-model="senha.senhaNova"></b-form-input>
                    <b-form-input type="password" v-if="this.senha.campoConfirmarVisivel" class="input form-group" placeholder="Confirme sua nova senha: " v-model="senha.senhaNovaConfirmar"></b-form-input>
                    <b-button variant="none" block class="button" @click="alterarSenha">Confirmar</b-button>

                </b-card>

                <br>
                
                <div style="color: #7A7A7A; text-align: center">

                    <span><a href="https://highfalutin-pencil-8e6.notion.site/Central-de-Ajuda-Contratei-26eb9a67a9444c8aa14d789083a343fe"
                    >Ajuda</a> | Technoplus Soluções em TI</span>

                </div>

            </div>

        </div>
        
    </div>
</template>

<script>
import api from "../../../services/api";

export default {

    data() {
        return {

            validado: false,

            senha: {
                token: null,
                senhaNova: null,
                senhaNovaConfirmar: null,
                campoConfirmarVisivel: false
            }

        };
    },

    created() {

        const token = this.$route.query.id;
        this.verificar(token)

    },

    methods: {

        msg(titulo, texto, cor) {
        this.toastCount++;
        this.$bvToast.toast(texto, {
            title: titulo,
            variant: cor,
            solid: true,
            autoHideDelay: 5000,
            appendToast: true
        });
        },

        verificar(token) {

            api.post(`web-cliente/verificar/${token}`)
            .then(() => {
                this.validado = true,
                this.senha.token = token
            })
            .catch((err) => {
                this.msg("ATENÇÃO", err.response.data, "danger");
            });

        },

        alterarSenha() {

            api.put('web-cliente/alterar-senha', this.senha)
            .then(async (res) => {
            
            if (res.status == 200) {

                this.senha.campoConfirmarVisivel = true
                this.msg('Confirme', 'sua senha', 'success')

            } else 
            if (res.status == 201) {

                this.msg('Parabéns', 'Sua senha foi alterada.', 'success')
                this.validado = false
                await new Promise(r => setTimeout(r, 4000));
                this.$router.push('/login')

            }  

            })
            .catch(err => {

            const erro = err.response.data

            if (erro.msgs) {

                erro.msgs.forEach(item => {
                this.msg('Atenção!', item, 'danger')
                })

            } 
            else {
                this.msg('Atenção!', err.response.data, 'danger')
            }

            })

        },

    }

}
</script>

<style lang="scss" scoped>

.background {
  width: 100%;
  position: fixed;
  background-color: #EEEEEE;
  background-image: linear-gradient(#1A75FF 0, #1A75FF 100%), linear-gradient(#EEEEEE 0, #EEEEEE 100%);
  background-size: 100% 50%;
  background-repeat: no-repeat;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.card {
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 0px;
}

.input {
    border-color: #237AFF;
}

.button {
    background-color: #237AFF;
    color: white;
    border-radius: 20px;
}

</style>