<template>
  <div id="dashboard">

    <Header/>

    <div>

      <b-jumbotron class="jumbotron">

        <h3 style="margin-top: -2rem">Análise de Dados</h3>

        <br>

        <b-row>

          <div class="col-lg-3 form-group">

            <b-row>

              <div class="col-lg-6 form-group">
              <b-form-select v-model="filtro.uf" :options="listaUfs" @change="changeUf">
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      UF: 
                    </b-form-select-option>
                  </template>
              </b-form-select>
            </div>

            <div class="col-lg-6 form-group">
              <b-form-select v-model="filtro.cidade" :options="listaCidades" @change="exportar">
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      Cidade: 
                    </b-form-select-option>
                  </template>
              </b-form-select>
            </div>

            </b-row>

          </div>

          <div class="col-lg-3 form-group">

            <b-row>
              <div class="col-lg-6 form-group">
                <b-form-datepicker id="periodoInicioSolicitacoes" 
                    placeholder="Início: "
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    v-model="filtro.periodoInicio"
                    @context="exportar">
                </b-form-datepicker>
              </div>

              <div class="col-lg-6 form-group">
                <b-form-datepicker id="periodoFimSolicitacoes" 
                    placeholder="Fim: "
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    v-model="filtro.periodoFim"
                    @context="exportar">
                </b-form-datepicker>
              </div>
            </b-row>

          </div>

          <div class="col-lg-3 form-group">

            <b-row>
              <div class="col-lg-6 form-group">
                <b-button block class="button" variant="none" @click="buscar" :style="cssVars">Buscar</b-button>
              </div>
              
              <div class="col-lg-6 form-group">
                <b-button block class="button" variant="none" :style="cssVars" @click="limparDadosFiltroSuperior">Limpar</b-button>
              </div>
            </b-row>

          </div>

          <div class="col-lg-3 form-group">
            <download-excel :data="arquivoExcel" name="planilha.xls">
              <b-button block class="button" variant="none" @click="exportar" :style="cssVars">Exportar</b-button>
            </download-excel>
          </div>

        </b-row>

        <b-row>

          <div class="col-lg-3 form-group">
            <b-card>
              <span>Novas solicitações </span>
              <h4>{{this.novasSolicitacoes}}</h4>
            </b-card>
          </div>

          <div class="col-lg-3 form-group">
            <b-card>
              <span>Contratos assinados </span>
              <h4>{{this.contratosAssinados}}</h4>
            </b-card>
          </div>

          <div class="col-lg-3 form-group">
            <b-card>
              <span>Tempo médio de contratação </span>
              <h4>{{this.tempoMedioContratacaoDias}} dia(s) e {{this.tempoMedioContratacaoHoras}} hora(s)</h4>
            </b-card>
          </div>

          <div class="col-lg-3 form-group">
            <b-card>
              <span>Tempo médio de análise </span>
              <h4>{{this.tempoMedioAnaliseDias}} dia(s) e {{this.tempoMedioAnaliseHoras}} horas(s)</h4>
            </b-card>
          </div>

        </b-row>

        <b-row>

          <div class="col-lg-12 form-group">

            <b-card>

              <span>Visão geral das solicitações</span>

              <br>
              <br>

              <b-row>

                <div class="col-lg-3 form-group">
                  <b-form-select :options="this.listaAtividades" v-model="filtro.atividade">
                      <template #first>
                        <b-form-select-option :value="null" disabled>
                          Visualizar por atividade: 
                        </b-form-select-option>
                      </template>
                  </b-form-select>
                </div>

                <div class="col-lg-3 form-group">
                  <b-form-select :options="this.listaStatus" v-model="filtro.status">
                    <template #first>
                      <b-form-select-option :value="null" disabled>
                        Visualizar por status: 
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
                
                <div class="col-lg-3 frm-group">
                  <div class="col-lg-6">
                    <b-button class="button" block variant="none" :style="cssVars" @click="limparDadosFiltroInferior">Limpar</b-button>
                  </div>
                </div>

              </b-row>

              <div id="chart" v-if="this.possuiDadosGraficoVisaoGeral == true">
                <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
              </div>

            </b-card>

          </div>

        </b-row>

        <b-row>

          <div class="col-lg-6">
            <b-card>
              <span>Números por Atividade</span>
              <div id="chart" v-if="this.possuiDadosGraficoPorAtividade == true">
                <br>
                <apexchart type="donut" width="421" :options="chartOptionsAtividade" :series="seriesAtividade"></apexchart>
              </div>
            </b-card>
          </div>

          <div class="col-lg-6">
            <b-card>
              <span>Números por Status</span>
              <div id="chart" v-if="this.possuiDadosGraficoPorStatus == true">
                <br>
                <apexchart type="donut" width="337" :options="chartOptionsStatus" :series="seriesStatus"></apexchart>
              </div>
            </b-card>
          </div>

        </b-row>

      </b-jumbotron>
    </div>
  </div> 
</template>

<script>

import Header from '../../components/Header.vue';
import VueApexCharts from 'vue-apexcharts';
import api from "../../services/api";


export default {
  name: 'Dashboard',
  
  components: {
    Header,
    apexchart: VueApexCharts,
  },

  data() {
    return {

    // Gráfico de Barras: 
          
      series: [{
        name: 'Em andamento',
        data: [0, 0, 0, 0]
      }, {
        name: 'Rejeitados',
        data: [0, 0, 0, 0]
      }, {
        name: 'Em aprovação',
        data: [0, 0, 0, 0]
      }, {
        name: 'Em validação',
        data: [0, 0, 0, 0]
      }, {
        name: 'Concluídos',
        data: [0, 0, 0, 0]
      }],

      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        colors: [
          '#2859D6', '#362778', '#CD65CD', '#D5FB65', 
          '#9A0CD4'
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Uma semana atrás', 'Duas semanas atrás', 'Três semanas atrás', 'Quatro semanas atrás'],
        },
        yaxis: {
          title: {
            text: 'Solicitações'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },

    //----------------------------------------------------------------------------------

    // Gráfico de Pizza (Atividade) 

      seriesAtividade: [0, 0, 0],
      chartOptionsAtividade: {
        colors: ['#EB5757', '#1A75FF', '#27AE60', '#362778', '#CD65CD', '#D5FB65', '#9A0CD4'],
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 250
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

    //----------------------------------------------------------------------------------

    // Gráfico de Pizza (Status) 

      seriesStatus: [0, 0, 0, 0, 0],
      chartOptionsStatus: {
        colors: ['#2859D6', '#362778', '#CD65CD', '#D5FB65', '#9A0CD4'],
        labels: [
          'Em andamento',
          'Rejeitados',
          'Em aprovação',
          'Em validação',
          'Concluídos'
        ],
        responsive: [{
          breakpoint: 480,
          options: {    
            chart: {
              width: 275
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },

    //----------------------------------------------------------------------------------

    filtro: {
      periodoInicio: null,
      periodoFim: null,
      cidade: null,
      atividade: null, 
      status: null,
      uf: null
    },

    dadosEmpresa: null,
    bgColor: null,
    fontColor: null,

    novasSolicitacoes: 0,
    contratosAssinados: 0,
    tempoMedioContratacao: 0,
    tempoMedioAnalise: 0,

    tempoMedioContratacaoDias: 0,
    tempoMedioContratacaoHoras: 0,

    tempoMedioAnaliseDias: 0,
    tempoMedioAnaliseHoras: 0,

    listaStatus: [
        'Em andamento',
        'Rejeitados',
        'Em aprovação',
        'Em validação',
        'Concluídos',
        'Todos'
    ],
  
    listaCidades: [],
    listaAtividades: [],
    listaUfs: [],
    
    possuiDadosGraficoVisaoGeral: false,
    possuiDadosGraficoPorStatus: false,
    possuiDadosGraficoPorAtividade: false,

    arquivoExcel: null,
    
    };
  },

  created() {

    this.dadosEmpresa = this.$store.state.empresaSelecionada;
    this.bgColor = this.dadosEmpresa.cor;
    this.fontColor = this.dadosEmpresa.corFonte;

    this.getCidades()
    this.getDadosGraficoTotais()
    this.getAtividades()
    this.getDadosGraficoVisaoGeral()
    this.getDadosGraficoPorAtividade()
    this.getDadosGraficoPorStatus()
    this.exportar()
    this.getUfs()

  },

  methods: {

    getCidades() {

      api.get(`web-cliente/dashboard/solicitacoes-cidades/${this.dadosEmpresa.id}/${this.filtro.uf}`)
      .then(res => {

        this.listaCidades = res.data
        this.listaCidades.push({

          text: 'Todas',
          value: 'null'

        })

      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))

    },

    getDadosGraficoTotais() {

      // Esta função é a responsável por preencher os campos "Novas solicitações", 
      // "Contratos assinados", "Tempo médio de contratação" e "Tempo médio de análise"

      api.get(`web-cliente/dashboard/solicitacoes-dados-totais/${this.dadosEmpresa.id}/${this.filtro.cidade}/${this.filtro.uf}/${this.filtro.periodoInicio}/${this.filtro.periodoFim}`)
      .then(res => {

        this.novasSolicitacoes = res.data.novasSolicitacoes,
        this.contratosAssinados = res.data.contratosAssinados,
        this.tempoMedioContratacao = res.data.tempoMedioContratacao,
        this.tempoMedioAnalise = res.data.tempoMedioAnalise

        this.tempoMedioContratacaoDias = 0
        this.tempoMedioAnaliseDias = 0

        while (this.tempoMedioContratacao >= 24) {

          this.tempoMedioContratacao = this.tempoMedioContratacao - 24
          this.tempoMedioContratacaoDias++

        }

        this.tempoMedioContratacaoHoras = this.tempoMedioContratacao

        while (this.tempoMedioAnalise >= 24) {

          this.tempoMedioAnalise = this.tempoMedioAnalise - 24
          this.tempoMedioAnaliseDias++

        }

        this.tempoMedioAnaliseHoras = this.tempoMedioAnalise

    
      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))

    },

    getAtividades() {

      // Esta função preenche a lista "Visualizar por atividade: "

      api.get(`web-cliente/dashboard/atividades-empresa/${this.dadosEmpresa.id}`)
      .then(res => {
        
        this.listaAtividades = res.data
        this.listaAtividades.push({

          text: 'Todas',
          value: 'null'

        })

      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))

    },

    getDadosGraficoVisaoGeral() {

      // Esta função preenche o gráfico "Visão geral das solicitações"

      api.get(`web-cliente/dashboard/solicitacoes-visao-geral/${this.dadosEmpresa.id}/${this.filtro.cidade}/${this.filtro.uf}/${this.filtro.periodoInicio}/${this.filtro.atividade}/${this.filtro.status}`)
      .then(res => {

        this.series[0].data = res.data.seriesEmAndamento
        this.series[1].data = res.data.seriesRejeitados
        this.series[2].data = res.data.seriesEmAprovacao
        this.series[3].data = res.data.seriesEmValidacao
        this.series[4].data = res.data.seriesConcluidos

        this.possuiDadosGraficoVisaoGeral = true
        
      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))

    },

    getDadosGraficoPorAtividade() {

      api.get(`web-cliente/dashboard/solicitacoes-atividade/${this.dadosEmpresa.id}/${this.filtro.cidade}/${this.filtro.uf}/${this.filtro.periodoInicio}/${this.filtro.periodoFim}`)
      .then(res => {

        const series = []
        const labels = []

        for (let i = 0; i < res.data.length; i++) {

          series[i] = res.data[i].quantidadeSolicitacoes
          labels[i] = res.data[i].nomeFuncao

        }

        if (series.length > 0 && labels.length > 0) {

          this.seriesAtividade = series
          this.chartOptionsAtividade.labels = labels
          this.possuiDadosGraficoPorAtividade = true

        }

      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))
    },

    getDadosGraficoPorStatus() {

      api.get(`web-cliente/dashboard/solicitacoes-status/${this.dadosEmpresa.id}/${this.filtro.cidade}/${this.filtro.uf}/${this.filtro.periodoInicio}/${this.filtro.periodoFim}`)
      .then(res => {

        this.seriesStatus = res.data
        
        if (this.seriesStatus == res.data) this.possuiDadosGraficoPorStatus = true
    
      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))
    },

    limparDadosFiltroSuperior() {

      this.filtro.cidade = null
      this.filtro.periodoInicio = null
      this.filtro.periodoFim = null
      this.filtro.uf = null

    },

    limparDadosFiltroInferior() {

      this.filtro.atividade = null
      this.filtro.status = null

    },

    buscar() {

      if (this.filtro.cidade == 'Todas') this.filtro.cidade = null
      if (this.filtro.uf == 'Todas') this.filtro.uf = null
      if (this.filtro.atividade == 'Todas') this.filtro.atividade = null
      if (this.filtro.status == 'Todos') this.filtro.status = null

      this.possuiDadosGraficoVisaoGeral = false
      this.possuiDadosGraficoPorAtividade = false
      this.possuiDadosGraficoPorStatus = false

      this.getDadosGraficoTotais()
      this.getDadosGraficoVisaoGeral()
      this.getDadosGraficoPorAtividade()
      this.getDadosGraficoPorStatus()
      this.exportar()

    },

    exportar() {

      api.get(`web-cliente/dashboard/exportar/${this.dadosEmpresa.id}/${this.filtro.cidade}/${this.filtro.uf}/${this.filtro.periodoInicio}/${this.filtro.periodoFim}`)
      .then(res => {

        this.arquivoExcel = res.data
    
      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))

    },

    getUfs() {

      api.get(`web-cliente/dashboard/solicitacoes-ufs/${this.dadosEmpresa.id}`)
      .then(res => {

        this.listaUfs = res.data
        this.listaUfs.push({

          text: 'Todas',
          value: 'null'

        })

      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))

    },

    changeUf() {

      this.filtro.cidade = null
      this.getCidades()
      this.exportar()

    }

  },

  computed: {

    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--font-color": this.fontColor
      }
    }

  }

}
</script>

<style lang="scss" scoped>

  #dashboard {
    background: #EBEFF3;
  }

  .button {
    background-color: var(--bg-color);
    border-color: var(--bg-color);
    color: var(--font-color);
  }

  .button:hover {
    color: var(--font-color);
  }

  .jumbotron {
    margin-bottom: 0rem;
  }

</style>>
