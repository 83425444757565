import firebase from "firebase";
import api from "../../../services/api";
import ImageUploader from 'vue-image-upload-resize';
// import { mapState } from 'vuex'

export default {
    props: ["emp"],

    components: {

        ImageUploader

    },

    data() {
        return {

            

            dadosEmpresa: null,
            bgColor: "#a1a1a1",

            blocos: [],

            documentacao: [],
            logo: "",
            listaImgRg: [], // monta html
            listaCarteiraConselho: [], // monta html
            listaNegativaConselho: [], // monta html
            listaDiploma: [], // monta html
            listaVacinacao: [], // monta html
            listaCurriculo: [], // monta html
            listaCriminais: [], // monta html
            listaAssinaturaCarimboCOREN: [], // monta html
            listaBlocoVariavel_1500: [], // monta html
            listaBlocoVariavel_1510: [], // monta html
            listaBlocoVariavel_1520: [], // monta html
            listaBlocoVariavel_1530: [], // monta html
            listaBlocoVariavel_1540: [], // monta html

            nomeFoto: [],
            fotoPerfil: null,
            uploadValue1: null,
            botaoVisivel: true,

            enderecoEdit: true,

            estadoCivil: ["SOLTEIRO(A)", "CASADO(A)", "DIVORCIADO(A)", "SEPARADO(A)", "VIÚVO(A)"],

            racas: [
                "BRANCA",
                "PRETA",
                "PARDA",
                "AMARELA",
                "INDÍGENA",
                "NÃO INFORMADO",
            ],

            sexo: ["MASCULINO", "FEMININO"],
            fumante: ["SIM", "NÃO"],

            labelDadosAdicionais: '',

            escolaridades: [],
            paises: [],
            bancos: [],

            listaConhecimentosTecnicos: [],

            tipoContas: ["CORRENTE", "POUPANÇA"],

            //   dispPlantoes: [],
            listaPlantoes: [
                { text: "Diurno Impar", value: "diaImpar" },
                { text: "Diurno Par", value: "diaPar" },
                { text: "Noturno Impar", value: "noiteImpar" },
                { text: "Noturno Par", value: "noitePar" },
            ],

            //   selProcedimentos: [],
            listaProcedimentos: [
                { text: "Manhã", value: "manha" },
                { text: "Tarde", value: "tarde" },
                { text: "Noite", value: "noite" },
            ],

            tiposDependentes: [],
            dependente: {
                nome: null,
                cpf: null,
                dataNasc: null,
                tipo: null,
            },
            editarDep: false,
            editarIndex: 0,

            fields: [
                { key: "nome", label: "Nome" },
                { key: "cpf", label: "CPF" },
                {
                    key: "dataNasc",
                    label: "Data Nascimento",
                    formatter: (value) => {
                        const dataView = value.split("-");
                        return `${dataView[2]}/${dataView[1]}/${dataView[0]}`;
                    },
                },
                {
                    key: "tipo",
                    label: "Tipo dependente",
                    formatter: (value) => {
                        const valor = this.tiposDependentes.filter(
                            (item) => item.value == value
                        )[0].text;
                        return valor.length > 27 ? valor.substring(0, 28) + "..." : valor;
                    },
                },
                { key: "actions", label: "Ações" },
            ],

            form: {

                nome: "",
                email: "",
                cpf: "",
                estadoCivil: "",
                nacionalidade: "",
                escolaridade: "",
                raca: "",
                dataNasc: "",
                sexo: "",
                restFisica: "",
                fumante: "",
                dadosAdic: "",
                rg: "",
                rgOrgaoEmissor: "",
                rgDataExp: "",
                nomeFuncao: "",
                regProf: "",
                regNum: "",
                regDataVencimento: "",
                pis: "",
                tituloEleitor: "",
                ccm: "",
                cep: "",
                cidade: "",
                uf: "",
                bairro: "",
                endereco: "",
                numEndereco: "",
                complemento: "",
                celular: "",
                celAdic: "",
                fixo: "",
                banco: "",
                agencia: "",
                conta: "",
                contaDig: "",
                tipoConta: "",
                chavePix: "",
                nomeBeneficiario: "",
                cpfBeneficiario: "",
                file1: "",
                dependentes: [],
                urlFotoPerfil: null,
                urlRg: [],
                urlCarteiraConselho: [],
                urlNegativaConselho: [],
                urlDiploma: [],
                urlVacinacao: [],
                urlCurriculo: [],
                urlAntCriminais: [],
                urlAssinaturaCarimboCoren: [],
                dadosBlocoVariavel_1000: null,
                dadosBlocoVariavel_1010: null,
                dadosBlocoVariavel_1020: null,
                dadosBlocoVariavel_1030: null,
                dadosBlocoVariavel_1040: null,
                urlBlocoVariavel_1500: [],
                urlBlocoVariavel_1510: [],
                urlBlocoVariavel_1520: [],
                urlBlocoVariavel_1530: [],
                urlBlocoVariavel_1540: [],
                nomeMae: "",
                nomePai: "",
                nomeConjuge: "",
                cidadeNascimento: "",
                ufNascimento: ""
            },

            show: true,
            img1: false,
            img2: false,

            ref: null,
            ufs: [],
            municipios: [],
            municipiosPorUF: []
        };
    },

    async created() {

        await this.getMunicipiosEUFs()
        await this.getSolicitacao()
        await this.getDadosEmpresa()
        await this.getBlocosReprovados()
        this.carregarMunicipios()

        this.logarFirebase()
    },

    // async mounted() {

    //     await this.getDadosEmpresa()
    //     await this.getBlocosReprovados()

    //     this.logarFirebase()

    // },

    methods: {

        async logarFirebase() {
            let userFirebase = this.$store.state.firebaseAuth.user
            await firebase.auth().signInWithEmailAndPassword(userFirebase.login, userFirebase.pass)
            userFirebase = null
        },

        async deslogarFirebase() {
            await firebase.auth().signOut()
        },


        msg(titulo, texto, cor, time = 10000) {
            this.toastCount++;
            this.$bvToast.toast(texto, {
                title: titulo,
                variant: cor,
                solid: true,
                autoHideDelay: time,
                appendToast: true,
            });
        },

        blocoVisivel(codBloco) {

            let visivel = false
            this.blocos.forEach(item => {
                if (item.cod == codBloco && item.visivel == 'sim')
                    visivel = true
            })

            return visivel
        },

        textoTitulo(codBloco) {

            let texto = ''
            this.blocos.forEach(item => {
                if (item.cod == codBloco)
                    texto = item.titulo
            })

            return texto
        },

        textoBloco(codBloco) {

            let texto = ''
            this.blocos.forEach(item => {
                if (item.cod == codBloco)
                    texto = item.instrucoes
            })

            return texto
        },

        textoBlocoMotivoRep(codBloco) {

            let texto = ''
            this.blocos.forEach(item => {
                if (item.cod == codBloco)
                    texto = item.motivo
            })

            return texto
        },


        selecionarImagem(base64, listaImg, idTarget) {
            
            this.hasImage = true
            this.image = base64

            // Verifica se o arquivo é um PDF, caso seja, ele não converte

            let arquivo = ''

            if (base64.type != 'application/pdf') {

                arquivo = this.converteBase64ParaObjeto(base64, `aquivo-foto-${idTarget}`)
                // console.log(arquivo)

            } else {

                arquivo = base64

            }

            // --------------------------------------------------------

            let nomeTratado = 'invalido'

            if (arquivo.type == 'image/png') nomeTratado = this.tratarNomeImg(`${arquivo.name}.png`)

            if (arquivo.type == 'image/jpeg') nomeTratado = this.tratarNomeImg(`${arquivo.name}.jpg`)

            if (arquivo.type == 'application/pdf') nomeTratado = this.tratarNomeImg(`${arquivo.name}.pdf`)

            // console.log('arquivo',arquivo.target.id)
            

            if (nomeTratado == 'invalido') {
                this.msg("ATENÇÃO", 'Arquivo inválido', "danger");
                return   
            }

            const urlTemp = this.getUrlTemp(arquivo)

            listaImg.push({ nome: nomeTratado, url: urlTemp })
            this.onUpload(arquivo, idTarget, nomeTratado)

            const input = document.getElementById(`${idTarget}*`)
            input.value = arquivo.name

        },

        converteBase64ParaObjeto(dataurl, filename) {
 
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            
            return new File([u8arr], filename, {type:mime});
        },

        apagarImagem(arquivoNome, listaImg, formUrl, campoDB, idInput) {

            // Deleta da lista que vai pro html e deleta do firebase
            var index = 0
            for (let i = 0; i < listaImg.length; i++) {
                if (listaImg[i] == arquivoNome)
                    index = i
            }

            listaImg.splice(index, 1);
            this.deleteFileFirebase(arquivoNome)


            //Deleta da lista de url de dentro do form
            const url = formUrl.filter(item => item.match(arquivoNome))

            for (let i = 0; i < formUrl.length; i++) {

                if (formUrl[i] == url)
                    index = i
            }

            formUrl.splice(index, 1);


            // Atualiza no banco de dados
            const params = {
                id: this.form.id,
                //urlRg: String(formUrl)
                [campoDB]: String(formUrl)
            }
            this.atualizaListaUrl(params)

            const input = document.getElementById(idInput)
            input.value = null
        },

        getUrlTemp(image) {
            // console.log(image)
            return URL.createObjectURL(image)
        },

        previwFoto(base64, idTarget) {

            this.hasImage = true
            this.image = base64

            // Verifica se o arquivo é um PDF, caso seja, ele não converte

            let arquivo = ''

            if (base64.type != 'application/pdf') {

                arquivo = this.converteBase64ParaObjeto(base64, `aquivo-foto-${idTarget}`)
                // console.log(arquivo)

            } else {

                arquivo = base64

            }

            // --------------------------------------------------------

            // var tagId = e.target.id
            if (idTarget == 'foto-perfil') {
                this.form.urlFotoPerfil = URL.createObjectURL(arquivo)
                
                let nomeTratado = 'invalido'

                if (arquivo.type == 'image/png') nomeTratado = this.tratarNomeImg(`${arquivo.name}.png`)

                if (arquivo.type == 'image/jpeg') nomeTratado = this.tratarNomeImg(`${arquivo.name}.jpg`)

                if (arquivo.type == 'application/pdf') nomeTratado = this.tratarNomeImg(`${arquivo.name}.pdf`)

                if (nomeTratado == 'invalido') {
                    this.msg("ATENÇÃO", 'Arquivo inválido', "danger");
                    return   
                }

                this.onUpload(arquivo, idTarget, nomeTratado)
                
                const input = document.getElementById(`${idTarget}*`)
                input.value = arquivo.name
            }
        },


        showModalDep() {
            this.editarDep = false;
            this.dependente = {};
            this.$refs["modal-dep"].show();
        },

        hideModalDep() {
            this.$refs["modal-dep"].hide();
        },

        saveDependente() {

            if (!this.dependente.nome) {
                this.msg("ATENÇÃO", 'Informe o nome.', "warning");
                return    
            }

            if (!this.dependente.dataNasc) {
                this.msg("ATENÇÃO", 'Informe a data de nascimento.', "warning");
                return    
            }

            if (!this.dependente.cpf) {
                this.msg("ATENÇÃO", 'Informe o CPF.', "warning");
                return    
            }
            
            if (!this.dependente.tipo) {
                this.msg("ATENÇÃO", 'Informe o tipo de dependente.', "warning");
                return    
            }


            this.$refs["modal-dep"].hide();

            if (this.editarDep == false) {
                this.form.dependentes.push(this.dependente);
            } else {
                this.form.dependentes.forEach((item, index) => {
                    if (index == this.editarIndex) item = this.dependente;
                });
            }
        },

        editarDependente(data) {
            this.editarDep = true;

            this.dependente = data.item;
            this.editarIndex = data.index;
            this.$refs["modal-dep"].show();
        },

        apagarDependente(index) {
            this.form.dependentes.splice(index, 1);
            this.$refs["modal-dep"].hide();
        },

        async getDadosEmpresa() {
            // console.log('ememem', this.$route.query.emp)
            const empresa = this.$route.query.emp
            // console.log('empresa', empresa2, key)

            try {
                const res = await api.get(`adesao/empresa/${empresa}/${this.form.atividadeProf}`)
                this.dadosEmpresa = res.data
                this.bgColor = res.data.cor
                this.logo = res.data.logoPath
                this.escolaridades = this.dadosEmpresa.escolaridades
                this.paises = this.dadosEmpresa.paises
                this.bancos = this.dadosEmpresa.bancos
                this.tiposDependentes = this.dadosEmpresa.tiposDependentes
                this.blocos = this.dadosEmpresa.blocos
                this.labelDadosAdicionais = this.dadosEmpresa.labelDadosAdicionais

                // console.log('ooooooooooooooo',res.data)
                // console.log('this.blocos.create', this.blocos)
                
                if (!this.dadosEmpresa) this.$router.push("/adesao/erro")

                // console.log('uuuuuuuuuuuuuuuuuuuuuu',res.data)
            } catch (error) {
                if (!this.dadosEmpresa) this.$router.push("/adesao/erro")
            }

        },

        async getSolicitacao() {
            // alert(this.$route.query.emp)
            const key = this.$route.query.key;

            try {
                
                const res = await api.get("adesao/empresa/documentacao/" + key)

                // console.log('aaaaaaaaaaaaaaaa',res.data);

                // Status permitidos
                if (res.data.status != 1 && res.data.status != 4 && 
                    res.data.status != 5 && res.data.status != 8) {


                    // console.log('aaaaaaaaaaaaaaaa');
                    
                    this.$router.push("/adesao/erro");
                }

                this.form = res.data;
                this.listaConhecimentosTecnicos = this.form.listaConhecimentosTecnicos;
                delete this.form.listaConhecimentosTecnicos;

                // this.imgRg = this.form.urlRg
                this.listaImgRg = this.form.urlRg.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaCarteiraConselho = this.form.urlCarteiraConselho.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaNegativaConselho = this.form.urlNegativaConselho.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaDiploma = this.form.urlDiploma.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaVacinacao = this.form.urlVacinacao.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaCurriculo = this.form.urlCurriculo.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaCriminais = this.form.urlAntCriminais.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaAssinaturaCarimboCOREN = this.form.urlAssinaturaCarimboCoren.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1500 = this.form.urlBlocoVariavel_1500.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1510 = this.form.urlBlocoVariavel_1510.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1520 = this.form.urlBlocoVariavel_1520.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1530 = this.form.urlBlocoVariavel_1530.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1540 = this.form.urlBlocoVariavel_1540.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })



                // Verifica CEP para liberar alteração no endereço.
                //----------------------------------------------
                const cep = this.form.cep.split('-')
                if (cep[1] == '000') this.enderecoEdit = false
                //----------------------------------------------

                if (!res.data.nacionalidade) this.form.nacionalidade = 105 

                // if (!this.dadosEmpresa) this.$router.push("/adesao/erro");

            } catch (error) {
                this.$router.push("/adesao/erro")                
            }


        },


        async getBlocosReprovados() {
            const key = this.$route.query.key;

            try {
                const res = await api.get("adesao/documentacao/blocos-reprovados/" + key)
                // console.log('blocoooooo',res.data)
                this.blocos = res.data;

                // console.log('this.blocos.create', this.blocos)

            } catch (error) {
                // this.msg("ATENÇÃO", 'Falha ao buscar blocos reprovados.', "danger");  
            }

        },

        async salvar(acao) {

            // console.log('1')
            
            if (this.form.urlFotoPerfil?.match('blob')) {
                // console.log('this.form.urlFotoPerfil')
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }
            // console.log('2')
            
            if (this.form.urlRg.length != this.listaImgRg.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlCarteiraConselho.length != this.listaCarteiraConselho.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlNegativaConselho.length != this.listaNegativaConselho.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlDiploma.length != this.listaDiploma.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlVacinacao.length != this.listaVacinacao.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlCurriculo.length != this.listaCurriculo.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlAntCriminais.length != this.listaCriminais.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlAssinaturaCarimboCoren.length != this.listaAssinaturaCarimboCOREN.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlBlocoVariavel_1500.length != this.listaBlocoVariavel_1500.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlBlocoVariavel_1510.length != this.listaBlocoVariavel_1510.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlBlocoVariavel_1520.length != this.listaBlocoVariavel_1520.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlBlocoVariavel_1530.length != this.listaBlocoVariavel_1530.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            if (this.form.urlBlocoVariavel_1540.length != this.listaBlocoVariavel_1540.length) {
                this.msg("ATENÇÃO", 'Aguarde o upload do arquivo...', "warning");
                return
            }

            this.botaoVisivel = false


            this.form.idEmpresa = this.dadosEmpresa.id
            this.form.empresa = this.dadosEmpresa.nomeEmpresa
            this.form.blocos = this.blocos

            this.form.nome = this.form.nome.toUpperCase()
            if (this.form.nomeMae) this.form.nomeMae = this.form.nomeMae.toUpperCase()
            if (this.form.nomePai) this.form.nomePai = this.form.nomePai.toUpperCase()
            if (this.form.nomeConjuge) this.form.nomeConjuge = this.form.nomeConjuge.toUpperCase()


            if (this.form.status != 8) this.form.status = 5
            if (acao == "enviar") this.form.status = 6

            if (this.ref) document.getElementById(this.ref).style.backgroundColor = '#FFFFFF';

            api.post("adesao/empresa/documentacao", this.form)
            .then(() => {
                this.msg('SUCESSO', 'DOCUMENTAÇÃO ENVIADA', "success", 5000);
                this.botaoVisivel = true
                this.deslogarFirebase()

                if (this.form.status == 5 || this.form.status == 8) localStorage.setItem('idMsg', 13)
                if (this.form.status == 6) localStorage.setItem('idMsg', 12)

                localStorage.setItem('nome', this.form.nome)
                localStorage.setItem('dadosEmpresa', JSON.stringify(this.dadosEmpresa))
                this.form = '';
                this.$router.push('/msg');

            })
            .catch((err) => {
                // this.onDeleteImage()
                // console.log(err)
                this.botaoVisivel = true

                if (err.response.status == 500) {
                    this.msg("ATENÇÃO", err.response.data, "danger")
                    return
                }
                else {
                    const erro = err.response.data.split('|')
                    this.ref = erro[1]
                    this.msg("ATENÇÃO", erro[0], "warning")
                }

                const el = this.$refs[this.ref]
                el.scrollIntoView({ behavior: 'smooth'})
                document.getElementById(this.ref).style.backgroundColor = '#F6DADD';
            })

        },

        async atualizaListaUrl(urls) {

            try {
                await api.put('adesao/empresa/documentacao/urls', urls)

            } catch (error) {
                this.msg("ATENÇÃO", error.response.data, "danger");
            }
        },

        tratarNomeImg(nomeImg) {
            // console.log('nomeImg', nomeImg)
            const nome = nomeImg.split(".");
            const agora = Math.floor(Date.now());
            const ext = nome[1].toLowerCase()

            if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'pdf')
                return agora + "." + nome[1]
            else
                return 'invalido'

        },



        async onUpload(file, idTag, nomeArquivoTratado) {
            
            // console.log("file", file)

            if (!file) return


            try {

                const storageRef = firebase.storage().ref()

                const metadata = {
                    // contentType: "image/jpeg,"
                    accept: [
                        '.jpg',
                        '.jpeg',
                        '.pdf',
                        '.png'
                    ]
                };

                let fileRef = storageRef.child(`${process.env.VUE_APP_FIREBASE_FOTOPERFIL}/${nomeArquivoTratado}`)
                if (idTag != 'foto-perfil')
                fileRef = storageRef.child(`${process.env.VUE_APP_FIREBASE_DOCUMENTOS}/${nomeArquivoTratado}`)

                // PARA TESTES
                // let fileRef = storageRef.child(`testes/${nomeArquivoTratado}`)
                // if (idTag != 'foto-perfil')
                // fileRef = storageRef.child(`testes/${nomeArquivoTratado}`)

                const uploadTaskSnapshot = await fileRef.put(file, metadata)
                const urlFirebase = await uploadTaskSnapshot.ref.getDownloadURL()


                if (idTag == 'foto-perfil') this.form.urlFotoPerfil = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (idTag == 'rg') this.form.urlRg.push(urlFirebase)
                else
                if (idTag == 'carteira-conselho') this.form.urlCarteiraConselho.push(urlFirebase)
                else
                if (idTag == 'negativa-conselho') this.form.urlNegativaConselho.push(urlFirebase)
                else
                if (idTag == 'diploma') this.form.urlDiploma.push(urlFirebase)
                else
                if (idTag == 'vacinacao') this.form.urlVacinacao.push(urlFirebase)
                else
                if (idTag == 'curriculo') this.form.urlCurriculo.push(urlFirebase)
                else
                if (idTag == 'ant-criminais') this.form.urlAntCriminais.push(urlFirebase)
                else
                if (idTag == 'AssinaturaCarimboCOREN') this.form.urlAssinaturaCarimboCoren.push(urlFirebase)
                else
                if (idTag == 'blocoVariavel_1500') this.form.urlBlocoVariavel_1500.push(urlFirebase)
                else
                if (idTag == 'blocoVariavel_1510') this.form.urlBlocoVariavel_1510.push(urlFirebase)
                else
                if (idTag == 'blocoVariavel_1520') this.form.urlBlocoVariavel_1520.push(urlFirebase)
                else
                if (idTag == 'blocoVariavel_1530') this.form.urlBlocoVariavel_1530.push(urlFirebase)
                else
                if (idTag == 'blocoVariavel_1540') this.form.urlBlocoVariavel_1540.push(urlFirebase)

            } catch (error) {
                console.log("ERR ===>", error)
            }

        },

        async onDeleteImage() {

            if (this.nomeFoto.length == 0) return

            try {

                this.nomeFoto.forEach(item => {

                    const storageRef = firebase.storage().ref()
                    var desertRef = storageRef.child(`fotos_perfil/${item}`)
                    desertRef.delete()
                })


            } catch (error) {
                console.log("ERR ===", error)
                    //alert("Image uploading failed!")
            }

        },

        async deleteFileFirebase(arquivoNome) {

            if (!arquivoNome) return

            try {

                const storageRef = firebase.storage().ref()
                var desertRef = storageRef.child(`fotos_perfil/${arquivoNome}`)
                desertRef.delete()


            } catch (error) {
                console.log("ERR ===", error)
                    //alert("Image uploading failed!")
            }

        },

        verificaHifen() {

            const regex = /-/
            const contemHifen = regex.test(this.form.rg)

            if (contemHifen) 
                return 'return event.charCode == 46 || event.charCode >= 48 && event.charCode <= 57 || event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122'
            else 
                return 'return event.charCode == 45 || event.charCode == 46 || event.charCode >= 48 && event.charCode <= 57'

        },

        abrirSeletorDeFotos(id) {

            document.getElementById(id).click()

        },

        async getMunicipiosEUFs() {

            api.get("adesao/documentacao/municipios")
            .then((res) => {
                
                this.ufs = res.data.ufs
                this.municipios = res.data.municipios

            }).catch((err) => {
                
                console.log(err)

            })

        },

        carregarMunicipios() {

            this.municipiosPorUF = this.municipios.filter(item => item.uf === this.form.ufNascimento);

            console.log(this.municipios)

        }

    },

    computed: {

        cssVars() {
            return {
                "--bg-color": this.bgColor,
                "--height": this.height + "px",
            };
        },

        
          

        // ...mapState( state => state.firebase.user ),
    },


};