<template>
    <div id="home">

        <div class="content p-1">
            <div class="list-group-item">
                <div class="d-flex">
                    <div class="mr-auto p-2">
                        <div class="display-4 title-azul">Home</div>
                    </div>

                    <div class="mr-auto p-2">
                        <div class="display-4 title-azul">Lero</div>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-lg-3 col-sm-6">
                        <div class="card bg-success text-white">
                            <div class="card-body">
                                <i class="fas fa-users fa-3x"></i>
                                <!-- <i class="fas fa-camera-retro"></i> -->
                                <div class="card-title">
                                    Clientes

                                    <h2 class="lead ml-4">{{ resultadoFiltro }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

</template>

<script>
import api from "@/services/api";

export default {
  name: "Home",
  data() {
    return {
      created: "",
      dataCliente: "",
      items: [],
    

      resultadoFiltro: "",
      resultadoFiltroPets: "",

      input: "",
      user: {}, //<--PEGAR INFORMAÇÕES DO USUARIO LOGADO
      loading: true,
    };
  },

  created() {
    const cliente = localStorage.getItem("cliente");

    this.form = JSON.parse(cliente);

    this.getClientes();

    this.getPets();

    this.loading = false;
  },

  methods: {
    getClientes() {
      this.items = [];
      this.load = true;
      this.resultadoFiltro = "(0) encontrados";
      api
        .get("cliente-list")
        .then((res) => {
          // console.log(res.data);
          this.items = res.data;
          this.load = false;
          this.resultadoFiltro = " " + this.items.length + " ";
        })
        .catch((err) => {
          this.load = false;
          this.msg("ERRO AO BUSCAR CLIENTES", err.response.data, "danger");
        });
    },

   
  },

  filters: {
    postLength(valor) {
      //Se o conteudo do post tiver mais que 200 caracteres acrecento...
      if (valor.length < 200) {
        return valor;
      }

      return `${valor.substring(0, 200)}...`;
    },
  },
};
</script>



<style lang="scss" scoped>
.portlet.box.blue {
  border: 1px solid #b4cef8;
  border-top-color: rgb(180, 206, 248);
  border-top-style: solid;
  border-top-width: 1px;
  border-top: 0;
}
.portlet.box {
  border-radius: 6px !important;
}
.portlet.box {
  padding: 0px !important;
}
.portlet-body.blue,
.portlet.blue {
  background-color: #4b8df8 !important;
}
.portlet {
  margin-bottom: 0;
}

.portlet {
  clear: both;
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px;
}

.blue,
.portlet.box.blue .portlet-title,
.ematendimento .dd3-handle {
  background-color: #3797de !important;
}
.portlet.box.blue .portlet-title {
  background-color: #4b8df8;
}
.color-white,
.portlet-title .caption i,
.portlet.box .portlet-title {
  color: #fff !important;
}
.portlet.box .portlet-title {
  border-radius: 6px 6px 0 0 !important;
}
.portlet.box .portlet-title {
  margin-bottom: 0px;
}
.portlet.box .portlet-title {
  padding: 8px 10px 2px 10px;
  border-bottom: 1px solid #eee;
  color: #fff !important;
}

.portlet.box .portlet-body {
  border-radius: 0 0 6px 6px !important;
}
.portlet.box .portlet-body {
  background-color: #fff;
  padding: 10px;
}
.coluna_widget .portlet-body {
  box-sizing: border-box;
  min-height: 215px;
}
.portlet-body {
  clear: both;
  padding: 0;
}

.portlet-title .caption {
  font-size: 15px;
}
.portlet-title .caption {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 0px;
  padding: 0;
  margin-bottom: 7px;
}

.feeds {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.feeds li {
  border-radius: 6px !important;
}
.feeds li {
  background-color: #fafafa;
  margin-bottom: 7px;
}
.light-gray {
  background-color: #eaeff2 !important;
}
li {
  line-height: 20px;
}

.feeds li::after {
  clear: both;
}
.feeds li::before,
.feeds li::after {
  display: table;
  line-height: 0;
  content: "";
}

li {
  line-height: 20px;
}
.feeds {
  list-style: none;
}

.feeds .col1 {
  float: left;
  width: 100%;
  clear: both;
}

.feeds li::after {
  clear: both;
}
.feeds li::before,
.feeds li::after {
  display: table;
  line-height: 0;
  content: "";
}

.feeds .col1 .cont .cont-col2 .desc {
  margin-left: 10px !important;
}
.feeds .col1 .cont .cont-col2 .desc {
  margin-left: 10px !important;
}
.feeds .col1 .cont .cont-col2 .desc {
  margin-left: 10px !important;
}
.feeds .col1 .cont .cont-col2 .desc {
  margin-left: 35px;
  padding-top: 4px;
  padding-bottom: 4px;
  overflow: hidden;
}
.linkAnimal {
  cursor: pointer;
}

.linkAnimal {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  direction: ltr;
}

.color-dark-gray,
.page-title {
  color: #50585c !important;
  // float: right;
  // margin-right: 15px;
  // margin-left: 15px;
  // border-top: 1px solid #dee2e6;
}
.date {
  text-align: center;
  float: right;
  padding-right: 15px;
  font-style: italic;
}

.feeds .col2 {
  margin-left: -120px !important;
  width: 120px !important;
}

.feeds .col2 {
  float: left;
  width: 75px;
  margin-left: -75px;
}

#home {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}

.title-azul {
  justify-items: center;
  justify-content: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
  margin-top: -10px;
  color: rgb(59, 152, 238);
  display: flex;
  margin-bottom: 2%;
}

.lead {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 2rem;
  justify-items: center;
  justify-content: center;
}

.card-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  float: right;
  line-height: 2rem;
  height: 50px;
}

// .card {

//     margin-bottom: 1rem !important;
// }

.content {
  width: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}
.p-1 {
  margin: 25px;
  padding: 0.25rem !important;
  justify-content: center;
  margin-top: 25px;
  color: #fff;
  white-space: pre-line;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

*,
::after,
::before {
  box-sizing: border-box;
}

.fa-users::before {
  content: "\f0c0";
}

.fa,
.fas {
  font-family: Font Awesome 5 Free;
  font-weight: 900;
}

.fa-3x {
  font-size: 3em;
}

.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.portlet-title::after {
  clear: both;
}

.portlet-title::after,
.portlet-title::before {
  content: "";
  display: table;
  line-height: 0;
}

.blue,
.portlet.box.blue .portlet-title,
.ematendimento .dd3-handle {
  background-color: #3797de !important;
}

.portlet.box.blue {
  border: 1px solid #b4cef8;
  border-top-color: #b4cef8;
  border-top-style: solid;
  border-top-width: 1px;
  border-top: 0;
  margin-top: 5rem;
  margin-left: 1rem;
}

.color-white,
.portlet-title .caption i,
.portlet.box .portlet-title {
  color: #fff !important;
}
.portlet.box .portlet-title {
  border-radius: 6px 6px 0 0 !important;
}

.portlet.box .portlet-title {
  margin-bottom: 0px;
}

.portlet.box .portlet-title {
  padding: 8px 10px 2px 10px;
  border-bottom: 1px solid #eee;
  color: #fff !important;
}

.portlet-title {
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}
</style>

