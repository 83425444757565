
<template>

  <div class="fundo ">
    <div class="backgra" :style="cssVars">
      <!-- <img
        :src="require('@/assets/logo/' + logo)"
        class="img-salus"
        alt="Salus"
      > -->
      <img
        :src="logo"
        class="img-salus"
        alt="Salus"
      >

      <div class="borda mx-auto col-lg-12">

        <div id="msg">
          <div class="">

            <!-- <div class="title-top">
              SOLICITAÇÃO ENVIADA
            </div> -->

            <div class="title-adesao">
              <span>
                <span v-html="mensagem"></span>
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import api from "../../services/api";

export default {
  name: "msg",

  data() {
    return {

      logo: '',
      bgColor: '',

      dadosEmpresa: '',
      nomeEmpresa: '',
      mensagem: '',

    };
  },

  created() {

    this.dadosEmpresa = JSON.parse(localStorage.getItem('dadosEmpresa'))
    const idMsg = localStorage.getItem('idMsg')
    this.nomeEmpresa = this.dadosEmpresa.nomeEmpresa

    this.bgColor = this.dadosEmpresa.cor
    this.logo = this.dadosEmpresa.logoPath    

    this.getMensagem(this.dadosEmpresa.id, idMsg)
  },

  methods: {

    getMensagem(idEmpresa, idMsg) {

      api
      .get(`adesao/msg/${idEmpresa}/${idMsg}`)
      .then((res) => {
        if (idMsg == 10) this.mensagem = res.data.split('xxx_empresa').join(' '+this.nomeEmpresa)
        if (idMsg == 13 || idMsg == 12) {
          const nome = localStorage.getItem('nome')
          this.mensagem = res.data.split('xxx_nome').join(' '+nome)
        }
        if (idMsg == 15) this.mensagem = res.data
      })
      .catch(() => {
        this.msg('ATENÇÃO', 'FALHA AO BUSCAR MENSAGEM', 'danger')
      });

    }

  },

  computed: {
    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--height": this.height + "px",
      };
    },
  },

};
</script>


<style  scoped>
.fundo {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .backgra {
  width: 100%;
  background-color: #bc0000;
  height: 140px;
  position: relative;
  z-index: 1;
} */

.backgra {
  width: 100%;
  background-color: var(--bg-color);

  height: 140px;
  position: relative;
  z-index: 1;
}

.img-salus {
  margin-top: 1rem;
}

.risco {
  margin-bottom: 1rem;
  border-bottom: 2px solid #a1a1a1;
  width: 100%;
}

.title-top {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  font-family: inherit, sans-serif;
  line-height: 11px;
  color: #ffffff;
}

@media screen and (max-width: 480px) {
  .title-top {
    font-size: 30px;
  }
}

.title-adesao {
  margin-top: 7rem;
  display: flex;
  text-align: justify;
  justify-content: center;
  justify-items: center;
  font-size: 29px;
  font-family: inherit, sans-serif;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 3rem;
  font-family: inherit, sans-serif;
  /* line-height: 11px; */
  color: #0e0d0d;
}

.borda {
  width: 50%;
  margin-top: 1rem;
}

@media screen and (max-width: 480px) {
  .borda {
    width: 100%;
    padding: 5px;
  }
}

@media screen and (max-width: 960px) {
  .borda {
    width: 100%;
    padding: 5px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

</style>