import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import firebaseAuth from './firebase'

Vue.use(Vuex)

const store = new Vuex.Store({
    // strict: true,
    devtools: false,
    namespaced: true,
    state: {
        empresaSelecionada: null
    },

    mutations: {
        setEmpresaSelecionada(state, empresa) {
            state.empresaSelecionada = empresa
        }
    },

    actions: {
        getAuth(state) {
            return state.isAuth
        }
    },


    modules: {
        auth: auth,
        firebaseAuth: firebaseAuth

    }
})

export default store