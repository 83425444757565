<template>
  <div id="alterarSenha">

    <Header/>
    
    <div class="background">

        <div v-if="this.visivel" style="width: 300px">

            <b-card class="card">

                <h4>Alterar Senha</h4>

                <br>

                <!-- Apenas para impedir o preenchimento automático -->
                <b-form-input type="password" class="input form-group" hidden></b-form-input> 
                <!-- ------------------------------------------------------- -->

                <b-form-input id="senhaAtual" type="password" :style="cssVars" class="input form-group" v-model="dadosUsuario.senhaAtual" placeholder="Sua senha atual:"></b-form-input>
                <b-form-input id="senhaNova" type="password" :style="cssVars" class="input form-group" v-model="dadosUsuario.senhaNova" placeholder="Sua nova senha:"></b-form-input>
                <b-form-input id="senhaNovaConfirmar" type="password" :style="cssVars" class="input form-group" v-model="dadosUsuario.senhaNovaConfirmar" placeholder="Confirme sua nova senha:"></b-form-input>

                <b-button :style="cssVars" variant="none" block class="button" @click="alterar">Confirmar</b-button>

            </b-card>

        </div>

    </div>

  </div>
</template>

<script>

import Header from '../../../components/Header.vue';
import api from "../../../services/api";

export default {
    name: 'AlterarSenha',

    components: {
    Header
    },

    data() {
        return {

            dadosEmpresa: null,
            corFundo: null,
            corFonte: null,

            visivel: true,

            dadosUsuario: {
                senhaAtual: null,
                senhaNova: null,
                senhaNovaConfirmar: null,
                email: null
            }

        };
    },

    methods: {

        msg(titulo, texto, cor, tempo) {

            this.toastCount++;
            this.$bvToast.toast(texto, {
                title: titulo,
                variant: cor,
                solid: true,
                autoHideDelay: tempo,
                appendToast: true
            });

        },

        alterar() {

            api
            .put("web-cliente/signalter", this.dadosUsuario)
            .then(async () => {
                
                this.visivel = false
                this.msg('Parabéns!', 'Sua senha foi alterada.', 'success', 3000)
                await new Promise(r => setTimeout(r, 3000));
                this.$router.push("/candidatos");
            })
            .catch(err => {

                const erro = err.response.data

                if (erro.msgs) {

                    erro.msgs.forEach(item => {
                        this.msg('Atenção!', item, 'danger', 5000)
                    })

                } 
                else {
                    this.msg('Atenção!', err.response.data, 'danger', 3000)
                }
            
            })

        },

    },

    created() {

        this.dadosEmpresa = this.$store.state.empresaSelecionada;
        this.corFundo = this.dadosEmpresa.cor;
        this.corFonte = this.dadosEmpresa.corFonte;

        this.dadosUsuario.email = localStorage.getItem('emailUsuario')
        this.dadosUsuario.email = this.dadosUsuario.email.split('"').join('')

    },

    computed: {

    cssVars() {
      return {
        "--cor-fundo": this.corFundo,
        "--cor-fonte": this.corFonte,
      }
    }

  }
}

</script>

<style lang="scss" scoped>

    .background {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: #EEEEEE;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .card {
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border: 0px;
    }

    .input {
        border-color: var(--cor-fundo);
    }

    .button {
        background-color: var(--cor-fundo);
        color: var(--cor-fonte);
        border-radius: 20px;
    }

</style>