<template>
<div>

  <div>

    <b-row class="form-group">
      <div style="margin-left: 25px">
          <b-avatar :src="candidato.urlFotoPerfil" size="90px"></b-avatar>
      </div>

      <div style="margin-left: 50px"> 
          <h5>{{ candidato.nome }}</h5>
          {{ candidato.email }}
      </div>

      <div style="margin-left: 50px"> 
          <h5>Emissão</h5>
          {{ dados.emissao }}
      </div>

      <div style="margin-left: 50px"> 
          <h5>Vencimento</h5>
          {{ dados.vencimento }}
      </div>

      <div style="margin-left: 50px"> 
          <h5>Finalização</h5>
          {{ dados.finalizacao }}
      </div>
    </b-row>


    <h3 class="mt-5 mb-4" style="margin-left: 10px">Signatários</h3>

    <b-table  
      responsive 
      hover 
      :fields="fields" 
      :items="dados.signatarios" 
      outlined
    >

    <template v-slot:cell(nome)="data">

      <h6>{{ data.item.nome }}</h6>
      <h6>{{ data.item.email }}</h6>

    </template>

    <template v-slot:cell(status)="data">

      <b-icon icon="check-circle-fill" scale="2" variant="success" v-if="data.item.status == 'Assinou'"></b-icon>
      <b-icon icon="clock-fill" scale="2" variant="warning" v-else-if="data.item.status == 'Aguardando'"></b-icon>
      <b-icon icon="exclamation-triangle-fill" scale="2" variant="danger" v-else></b-icon>
      <abbr class="status-texto">{{ data.item.status }}</abbr>

    </template>

    </b-table>

  </div>

</div>
</template>
  
  <script>
  import api from "@/services/api";
  // import dayjs from "dayjs";
  
  export default {

    name: "assinaturas",
    props: {
      candidato: {}
    },
  
    data() {
      return {

        dados: {
          emissao: null,
          vencimento: null,
          finalizacao: null,
          signatarios: []
        },

        fields: [
          { key: "nome", label: "Nome" },
          { key: "status", label: "Status" },
          { key: 'dataAssinatura', label: 'Data assinada' },
        ],
  
      };
    },
  
    created() {

      this.getHistoricoAssinaturas()  
    },
  
    methods: {
  
      msg(titulo, texto, cor, time = 10000) {
          this.toastCount++;
          this.$bvToast.toast(texto, {
            title: titulo,
            variant: cor,
            solid: true,
            autoHideDelay: time,
            appendToast: true,
          });
      },

      getHistoricoAssinaturas() {


        api.get(`web-cliente/candidato/historico-assinaturas/${this.candidato.id}`)
        .then(res => {

          // console.log('qqqqqqqqqqqqqqqqqqqqqqqqq',res.data)
          this.dados = res.data

        })
        .catch(err => this.msg('Deu errado '+err.response.data, 'vermelho', 5000))

      },
    
    },
  

  };
  </script>
  
  
  <style scoped>

  .status-texto {
    font-size: 22px; 
    margin-left: 25px;
  }

  </style>
  
  
  