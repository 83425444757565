import Vue from 'vue'
import App from './App.vue'
import CKEditor from 'ckeditor4-vue'

import router from './router'
import store from "./stores/global";
import firebase from './services/firebaseConnection';

import '@babel/polyfill'
import 'mutationobserver-shim'
import './plugins/bootstrap-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { JumbotronPlugin } from 'bootstrap-vue'
import { TabsPlugin } from 'bootstrap-vue'
import { BContainer } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VMask from "v-mask";
// import store from './stores/global';
import { FormDatepickerPlugin } from 'bootstrap-vue'
import { BIcon } from 'bootstrap-vue'
import { BFormRating } from 'bootstrap-vue'
import { BPagination } from 'bootstrap-vue'
import { ProgressPlugin } from 'bootstrap-vue'


import { TablePlugin } from 'bootstrap-vue'

import JsonExcel from "vue-json-excel";





Vue.use(ProgressPlugin)
Vue.use(TablePlugin)

Vue.component('b-pagination', BPagination)
Vue.component('b-form-rating', BFormRating)
Vue.use(CKEditor)
Vue.use(FormDatepickerPlugin)
Vue.use(VMask);
Vue.use(BootstrapVue)
Vue.use(TabsPlugin)
Vue.component('b-container', BContainer)
Vue.component("downloadExcel", JsonExcel);
Vue.use(IconsPlugin)
Vue.component('b-icon', BIcon)
Vue.use(JumbotronPlugin)

Vue.config.productionTip = false



/* onAuthStateChanged VAI VERIIFICAR AS ROTAS SE É TRUE OU FALSE */

let app
firebase.auth().onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            router,
            store,
            render: h => h(App),
        }).$mount('#app')
    }
})

//new Vue({
//   router,
//    render: h => h(App)
//}).$mount('#app')