<template>
  <div class="page">


    <div class="wrapper">
      
      <div class="card" :class="item.classCSSCorCard" v-for="item in avisos" v-bind:key="item.id">
        
        <span class="title" @click="alterarStatusLeitura($event, item.id)">Marcar como não lido</span>
        <span class="data">{{item.data}}</span>

        <p v-html="item.mensagem"></p>
        

      </div>

    </div>

  </div>
</template>



<script>
import api from "@/services/api";
// import { msg } from "@/global";
export default {
  name: "ModalAvisos",
  props: {
    setAvisosTodos: null
  },

  data() {
    return {

      avisos: [],

      // avisos: [
      //   { id: 1, titulo: 'Opa negada', mensagem: 'hehe :)', usuario: 'Marcos', data: '09/09/2023' },
      //   { id: 2, titulo: 'Opa negada', mensagem: 'hehe :)', usuario: 'Marcos', data: '09/09/2023' },
      //   { id: 3, titulo: 'Opa negada', mensagem: 'hehe :)', usuario: 'Marcos', data: '09/09/2023' },
      //   { id: 4, titulo: 'Opa negada', mensagem: 'hehe :)', usuario: 'Marcos', data: '09/09/2023' }
      // ],   
  
    }
  },

  created() {

    // console.log(this.getAvisosTodos,'iiiiiiiiiiiiiiiiii')

    if (this.setAvisosTodos) {
      this.toMostrarAvisos()  
    }
    else {
      this.getAvisos()
    }

  },

  methods: {


    msg(titulo, texto, cor, time = 10000) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: time,
        appendToast: true,
      });
    },

    getAvisos() {
      
      // this.avisos = JSON.parse(localStorage.getItem('avisos'))
      api.get('web-cliente/painel-admin/avisos')
      .then((res) => {

        // alert('opopopopo')
        // console.log('oooooo')
        this.avisos = res.data


        this.atualizaAvisosParaLidos()        
      })
      .catch(() => {
        this.msgNotification('err.response.data', 'error', 5000)
      });
    },


    toMostrarAvisos() {

      api.get('web-cliente/painel-admin/avisos-todos')
      .then((res) => {

        this.avisos = res.data
      })
      .catch(() => {
        this.msg('err.response.data', 'error', 5000)
      });
    },

    
    atualizaAvisosParaLidos() {

      // const avisos = this.avisos.filter(item => item.classCSSCorCard == 'card-nao-lido')
      const avisos = this.avisos.map(item => item.id)
      if (avisos.length == 0) return
      
      api.put('web-cliente/painel-admin/avisos', { avisosIds: avisos })
      .then(() => {})
      .catch(() => {
        this.msg('err.response.data', 'error', 5000)
      });

    },

    alterarStatusLeitura(item, idAviso) {
      // console.log(item.target.textContent)

      const texto = item.target.textContent
      if (texto == 'Marcar como não lido') {
        var marcarComoNaoLido = true
        item.target.textContent = 'Marcar como lido'
      }
      else {
        marcarComoNaoLido = false
        item.target.textContent = 'Marcar como não lido'
      }

      api.patch('web-cliente/painel-admin/avisos', { idAviso, marcarComoNaoLido: marcarComoNaoLido })
      .then(() => {})
      .catch((err) => {
        this.msgNotimsgfication(err.response.data, 'error', 5000)
      });
    }

  },

  beforeDestroy() {

    // api.get(`avisos`)
    // .then((res) => {
        
    //     this.avisos = res.data
    //     localStorage.setItem('avisos', JSON.stringify(res.data))

    // })
    // .catch((err) => { this.msg(err.response.data, 'error', 5000) }) 

  }

};
</script>



<style scoped>

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.page {
  width: 100%;
  /* background-color: aqua; */
}

.wrapper{
  max-width: 880px;
  margin: 50px auto;
  padding:  0 20px;
}

.card {
  margin-top: 15px;
  border: #8ed1fc;
  /* border: #a06dcf; */
  border-style: solid;
  border-width: 4px;
  border-radius: 15px;
  border-top-width: 45px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
}

.card-nao-lido {
  background: #a06dcf;
}

.card-lido {
  background: #ffffff;
}

.title {
  align-self: self-end;
  margin-bottom: 10px;
  margin-top: -50px;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  /* font-weight: 600; */
}

.textosCor-Lido {
  color: #a06dcf;
}

.textosCor-naoLido {
  color: white;
}

.data{
  margin-top: -27px;
  margin-bottom: 17px;
  font-size: 17px;
  color: #000;
}


.card p{
  margin: 30px 0 17px 0;
  font-size: 20px;
  font-weight: 600;
}



@media(max-width: 772px){

  .wrapper .center-line{
    left: 40px;
  }
  .wrapper .row{
    margin: 30px 0 3px 60px;
  }
  .wrapper .row {
    width: 100%;
  }

}



</style>