import api from "@/services/api";




async function validaCredencial(codCredencial) {

    // console.log('codCredencial', codCredencial)

    try {

        const res =  await api.post('web-cliente/check-credencial', { codCredencial })
        // console.log(res.data)
        return res.data
        
    } catch (error) {
        return 'fail' 
    }
}


export default validaCredencial;