<template>
  <div id="app">
     <!-- <Header v-if="statusMenu"/> -->


<!-- <Header/> -->


    
    <!-- <Sidebar-component /> -->
    
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/usuario">Usuario</router-link> |
      <router-link to="/cooperado">Cooperado</router-link>
    </div> -->
    <router-view/>
  </div>
</template>


<script>

// import Header from './components/Header'
// import SidebarComponent from './components/SidebarComponent'

export default {
  components: {
    // Header,
    // SidebarComponent
  },

  created() {
    
    // Deixa acessar pagina sem autenticar se for o caminho abaixo, se não ele direciona para o login.
    try {

      // console.log("iniciouuu",this.$router);
      // console.log("path", path);

      const path = this.$router.history._startLocation.split('?')[0]

      if (path == '/adesao/pre-cadastro' || path == '/adesao/documentacao' || path == '/adesao/cancelar' || path == '/nova-senha') {
        this.$router.push(this.$router.history._startLocation)
      } else {
        this.$router.push("/login") 
      }

      
    } catch (error) {
        this.$router.push("/login") 
    }
      // this.validateToken()
  },

  computed: {
    statusMenu() {
      return this.$store.state.auth.isMenuVisible
    }
  }
}
</script>









<style lang="scss">



</style>
