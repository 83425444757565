import Vue from 'vue';
import Router from 'vue-router';
import firebase from '../services/firebaseConnection';

import Erro from '../pages/web_cliente/Erro'
// import Erro2 from '../pages/adesao/Erro'

import Home from '../components/views/Home';
import Candidatos from '../pages/web_cliente/candidatos/Candidatos';
import CandidatoConsultar from '../pages/web_cliente/candidatos/consultar/Consultar';
import Avaliar from '../pages/web_cliente/candidatos/avaliar/Avaliacao';
import CancelamentoAdesao from '../pages/web_cliente/CancelamentoAdesao';


import Login from '../pages/web_cliente/auth/Login';
import NovaSenha from '../pages/web_cliente/auth/NovaSenha';
import AlterarSenha from '../pages/web_cliente/auth/AlterarSenha';
import Dashboard from '../pages/web_cliente/Dashboard';
import Perfil from '../pages/web_cliente/Perfil';
import PainelAdmin from '../pages/web_cliente/painelAdmin/PainelAdmin';


// Adesão
//--------------------------------------------------------
import PreCadastro from '../pages/adesao/pre_cadastro/PreCadastro';
import Documentacao from '../pages/adesao/documentacao/Documentacao';
import Msg from '../pages/adesao/Msg';
//--------------------------------------------------------



Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            component: Home,
            meta: {
                requiresAuth: true, //<--SÓ VAI ACESSAR SE ESTIVER LOGADO, E UMA ROTA PROTEGIDA
            }
        },

        {
            path: '/perfil/:userid',
            component: Perfil,
            props: true, //<--PARA PODE RECEBER O ID ATRAVEZ DAS PROPRIEDADE, PASSO OO PROPS TRUE
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/login',
            component: Login
        },

        {
            path: '/nova-senha',
            component: NovaSenha
        },

        {
            path: '/alterar-senha',
            component: AlterarSenha
        },

        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
            //     requiresAuth: true,
            }
        },

        {
            path: '/painelAdmin',
            name: 'PainelAdministrativo',
            component: PainelAdmin,
            meta: {
            //     requiresAuth: true,
            }
        },

        {
            path: '/candidatos',
            name: 'Candidatos',
            component: Candidatos,
            meta: {
                // requiresAuth: true,
            }
        },

        {
            path: '/candidato/consultar',
            name: 'CandidatoConsultar',
            component: CandidatoConsultar,
            meta: {
                //requiresAuth: true,
            }
        },

        {
            path: '/candidato/avaliar',
            name: 'Avaliar',
            component: Avaliar,
            meta: {
                //requiresAuth: true,
            }
        },

        //erro 404
        {
            path: '*',
            component: Erro
        },




        // Adesão 
        //---------------------------------------------------------
        {
            path: '/adesao/erro',
            component: Erro
        },

        {
            path: '/adesao/pre-cadastro',
            component: PreCadastro,
            name: 'emp'
        },

        {
            path: '/adesao/documentacao',
            component: Documentacao,

        },

        {
            path: '/msg',
            component: Msg
        },

        {
            path: '/adesao/cancelar',
            component: CancelamentoAdesao,
            meta: {
                // requiresAuth: true,
            }
        },
        //---------------------------------------------------------
    

    ]
});


router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

    if (requiresAuth && !firebase.auth().currentUser) {
        next('/login');
    } else {
        next();
    }

})

/* AGORA VOU EM (MAIN.JS) PARA VERIFICAR SE TEM USUÁRIO LOGADO */

export default router;