import { render, staticRenderFns } from "./assinaturas.vue?vue&type=template&id=6be5c851&scoped=true"
import script from "./assinaturas.vue?vue&type=script&lang=js"
export * from "./assinaturas.vue?vue&type=script&lang=js"
import style0 from "./assinaturas.vue?vue&type=style&index=0&id=6be5c851&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be5c851",
  null
  
)

export default component.exports