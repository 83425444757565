<template>
  <div id="header">
    <b-navbar toggleable="lg" type="gradiente" class="backgraund" :style="cssVars" >

      <b-navbar-toggle  v-model="aberto" class="btn-celular" aria-haspopup="true" aria-expanded="true" target="nav-collapse" ></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav>
          <div>
            <!-- <b-img center :src="require('@/assets/logo/' + logo)" alt="TECHNO PLUS" -->
            <b-img center :src="logo" alt="TECHNO PLUS"
            style="cursor: pointer"
            @click="toGestaoCandidatos"></b-img>
          </div>
        </b-navbar-nav>

        <!-- Itens de navegação alinhados à direita -->
        <b-navbar-nav class="user-celular ml-auto">

          <template :style="cssVars">
            <em class="nome-usuario"
            style="cursor: pointer; padding: 7px"
            @click="toGestaoCandidatos" >Gerenciar candidatos •</em>
          </template>


          <template :style="cssVars">
            <em class="nome-usuario"
            style="cursor: pointer; padding: 7px"
            @click="toDashboard"  v-if="acessoDashboard">Analisar Dados •</em>
          </template>

          <b-nav-item-dropdown  right :style="cssTitulo">

            <template #button-content :style="cssVars">
              <em class="nome-usuario">{{ usuario }}</em>
            </template>

            <b-dropdown-item @click="toPainelAdmin" v-if="acessoPainelAdmin">Painel Administrativo</b-dropdown-item>

            <b-dropdown-item @click="toAlterarSenha">Alterar Senha</b-dropdown-item>

            <b-dropdown-item @click="toMostrarAvisos">Avisos</b-dropdown-item>

            <!-- <b-dropdown-item href="https://highfalutin-pencil-8e6.notion.site/O-que-h-de-novo-974bc4edebf045b2b3d02b0119810207" target="_blank"
            >Detalhes da versão</b-dropdown-item> -->

            <b-dropdown-item href="https://technoplusltda.zohodesk.com/portal/pt-br/kb/articles/contratei-oque-ha-de-novo" target="_blank"
            >Ajuda</b-dropdown-item>

            
            <b-dropdown-item @click="logOut">Sair</b-dropdown-item>
       

          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>


    <!-- MODAL AVISOS -->
    <div>
      <b-modal ref="modal-avisos" hide-footer title="Avisos!" size="lg">

        <ModalAvisos :setAvisosTodos="setAvisosTodos">
        </ModalAvisos> 

      </b-modal>
    </div>
    <!-- MODAL AVISOS FIM -->



  </div>

</template>

<script>
import firebase from "../services/firebaseConnection";
import api from "../services/api";
import validaCredencial from "../../src/pages/web_cliente/utils";
import ModalAvisos from '../../src/pages/web_cliente/painelAdmin/modal/ModalAvisos';

export default {
  name: "Header",
  components: { 
    ModalAvisos
   },

  data() {
    return {

      bgColor: '',
      fontColor: '',
      logo: '',
      aberto: true,
      usuario: "",
      acessoDashboard: false,
      acessoPainelAdmin: false,

      setAvisosTodos: false

    };
  },

  
  methods: {

    msg(titulo, texto, cor, time = 10000) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: time,
        appendToast: true,
      });
    },

    async toPainelAdmin() {

      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(100)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning", 3000);
        return
      }
      //------------------------------------------------------------------


      this.$router.push("/painelAdmin")
    },

    toGestaoCandidatos() {
      this.$router.push("/candidatos")
    },

    async toDashboard() {

      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(31)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning", 3000);
        return
      }
      //------------------------------------------------------------------


      this.$router.push("/dashboard")
    },

    toAlterarSenha() {
      this.$router.push("/alterar-senha")
    },

    async logOut() {
      const confirm = window.confirm("Você realmente deseja sair?");

      if (confirm) {
        delete api.defaults.headers.common['Authorization']
        this.$store.commit('auth/setUser', null)
        this.$store.commit('setEmpresaSelecionada', null)

        // Firebase
        await firebase
          .auth()
          .signOut()
          .then(() => {
            localStorage.removeItem("usuario");
            this.usuario = "";
            this.$router.push("/login");
          });
      } else {
        return;
      }
    },


    getAvisos() {

      this.setAvisosTodos = false
      
      api.get('web-cliente/painel-admin/avisos')
      .then((res) => {

        this.avisos = res.data

        if (this.avisos.length > 0) 
        this.$refs["modal-avisos"].show();
     
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
      });
    },


    toMostrarAvisos() {

      this.setAvisosTodos = true
      this.$refs["modal-avisos"].show()
    }

  },

  computed: {

    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--height": this.height + "px",
        "--font-color": this.fontColor,
        
      };
    },

    cssTitulo() {
      return {
        "--color": this.color,
        "--height": this.height + "px",
       
      };
    }
  },

  async mounted() {

    const empresa = this.$store.state.empresaSelecionada
    this.bgColor = empresa.cor;
    this.fontColor = empresa.corFonte;
    this.logo = empresa.logoPath;
    this.color = empresa.titulo;
    // const local = localStorage.getItem("usuario");
    const user = this.$store.state.auth.user
    this.usuario = user.nome;

    // Valida credenciais
    //------------------------------------------------------------------
    var acessoPermitido = await validaCredencial(31)
    acessoPermitido == 'fail' ? this.acessoDashboard = false : this.acessoDashboard = true

    acessoPermitido = await validaCredencial(100)
    acessoPermitido == 'fail' ? this.acessoPainelAdmin = false : this.acessoPainelAdmin = true
    //------------------------------------------------------------------


    // alert('oioio')
    // this.$refs["modal-avisos"].show();
    this.getAvisos()

  },
};
</script>



<style scoped>

.dropdown-toggle {
    white-space: nowrap;
    color: rgb(0, 0, 0);
}

.user-celular {
  color: #000;
}

[data-v-61dd7a3d], [data-v-61dd7a3d]::before, [data-v-61dd7a3d]::after {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  box-sizing: border-box;
  color: var(--color);
}

@media screen and (max-width: 480px) {
    [data-v-61dd7a3d], [data-v-61dd7a3d]::before, [data-v-61dd7a3d]::after {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.2;
      box-sizing: border-box;
      color: var(--color);
    }
}

*, ::before, ::after {
    box-sizing: border-box;
     color: #000;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(197 45 186 / 25%) !important;
}

.backgraund {
  background-color: var(--bg-color);
  z-index: 8;
}

.btn-celular {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 50%;
}

img {
  padding-top: 7px;
  padding-bottom: 7px;
  height: 70px;
}

.nome-usuario {
  color: var(--font-color);
}

</style>


