import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// import 'firebase/storage';



let firebaseConfig = {
    apiKey: "AIzaSyDeTDjw4vGDg-aP45wQlS456B7Os2eubE4",
    authDomain: "contratei-716c1.firebaseapp.com",
    projectId: "contratei-716c1",
    storageBucket: "contratei-716c1.appspot.com",
    messagingSenderId: "654466688685",
    appId: "1:654466688685:web:6151c00dceb5d064e94824",
    measurementId: "G-GBH19N7MX8"
};


/* Si não tiver conexão ele inicializa */
if (!firebase.apps.length) {
    /* Initialize Firebase */
    firebase.initializeApp(firebaseConfig);
}


export default firebase;