<template>

  <div id="cancelarAdesao">

    <div>

        <b-jumbotron class="jumbotron" :style="cssVars">
            <img :src="require('@/assets/logo/' + logo)" class="img-salus" alt="logo" />
        </b-jumbotron>

    </div>

    <div v-if="jaSolicitado == false">
        
        <h3 class="texto">Tem certeza que deseja solicitar o <b>cancelamento do seu processo de adesão?</b></h3>
        <br>
        <h3 class="texto"> Seus dados serão <b>excluídos</b> do nosso sistema.</h3>

    </div>

    <div v-if="jaSolicitado == true">
        
        <h3 class="texto">Sua solicitação foi enviada.</h3>

    </div>

    <br>
    <br>
    <br>

    <b-row class="botoes" v-if="jaSolicitado == false">

        <div class="col-lg-3 form-group">
            <b-button block variant="success" @click="confirmar"><b style="font-size: 20px">Confirmar cancelamento</b></b-button>
        </div>

    </b-row>
    
  </div>

</template>

<script>
import api from "../../services/api";

export default {

    name: 'CancelarAdesao',

    data() {
        return {

            idEmpresa: null,
            idSolicitacao: null,
            cpfCandidato: null,

            statusSolicitacao: null,

            bgColor: '#000000',
            logo: null,

            jaSolicitado: false

        }
    },
    
    created() {

        this.idEmpresa = this.$route.query.emp
        this.idHash = this.$route.query.key

        this.getEmpresa()
        this.getStatusSolicitacao()

    },

    methods: {

        msg(titulo, texto, cor) {
            this.toastCount++;
            this.$bvToast.toast(texto, {
                title: titulo,
                variant: cor,
                solid: true,
                autoHideDelay: 5000,
                appendToast: true,
            });
        },

        getEmpresa() {

            api.get(`web-cliente/cancelar-adesao/empresa/${this.idEmpresa}`)
            .then(res => {

                this.bgColor = res.data.cor
                this.logo = res.data.logoPath
            
            })
            .catch(err => this.msg(err.response.data, 'vermelho', 5000))

        },
        
        getStatusSolicitacao() {

            api.get(`web-cliente/cancelar-adesao/solicitacao-status/${this.idEmpresa}/${this.idHash}`)
            .then(res => {

                this.statusSolicitacao = res.data
            
            })
            .catch(err => this.msg(err.response.data, 'vermelho', 5000))

        },

        confirmar() {

            this.getStatusSolicitacao()


            if (this.statusSolicitacao != 10) {

                if (this.statusSolicitacao != 12) {

                    api.put(`web-cliente/cancelar-adesao/confirmar/${this.idEmpresa}/${this.idHash}`)
                    .then(() => { this.jaSolicitado = true })
                    .catch(err => this.msg(err.response.data, 'vermelho', 5000))

                } else this.msg('ATENÇÃO', 'A exclusão de seus dados já foi solicitada.', 'warning')

            } else {

                this.msg('ATENÇÃO', 'Você já é um cooperado, portanto, já teve o processo de adesão finalizado.', 'warning')

            }

        },

    },

    computed: {

        cssVars() {
            return {
            "--bg-color": this.bgColor
            }
        }

    },
}

</script>

<style lang="scss" scoped>

    .jumbotron {
        background-color: var(--bg-color);
        height: 240px;
        border-radius: 0px;
    }

    .texto {
        text-align: center;
    }

    .botoes {
        align-items: center;
        justify-content: center;
    }

</style>