<template>
    <div id="perfil">
        <h2>Esté é componente Perfil</h2>

        <!--CHAMO O COMPONENTE USUARIO DO APP.VUE COLOCANDO APENAS O SLOT, o que estiver dentro de Usuario será renderizado-->
        <slot>

        </slot>

    </div>

</template>

<script>
export default {
  name: "Perfil",
  props: ["nome"]
};
</script>

<style scoped>

</style>


